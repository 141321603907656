import React from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./Navbar.module.css";

const Navbar = () => {
  const location = useLocation();
  return (
    <nav className={styles.main_nav}>
      <div className={styles.nav}>
        <Link to="/">
          <img
            src={process.env.PUBLIC_URL + "/logo.png"}
            className={styles.logo}
            alt="Your Logo"
            height="50"
          />
        </Link>

        <ul className={styles.menus}>
          <li className={location.pathname === "/" ? styles.active : ""}>
            <Link to="/">Home</Link>
          </li>

          <li
            className={location.pathname === "/sgx_nifty" ? styles.active : ""}
          >
            <Link to="/sgx_nifty">SGX Nifty</Link>
          </li>

          <li className={location.pathname === "/zerodha" ? styles.active : ""}>
            <Link to="/zerodha">Zerodha</Link>
          </li>

          <li className={location.pathname === "/upstox" ? styles.active : ""}>
            <Link to="/upstox">Upstox</Link>
          </li>

          <li
            className={
              location.pathname === "/fyers/fyers" ? styles.active : ""
            }
          >
            <Link to="/fyers/fyers">Fyers</Link>
          </li>

          <li
            className={location.pathname === "/contact_us" ? styles.active : ""}
          >
            <Link to="/contact_us">Contact Us</Link>
          </li>

          <li
            className={location.pathname === "/about_us" ? styles.active : ""}
          >
            <Link to="/about_us">About Us</Link>
          </li>
        </ul>

        <button
          className={location.pathname === "/getin" ? styles.disappear : ""}
        >
          <Link to="/getin">Login / Signup</Link>
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
