import React from "react";
import Navbar from "../../../Widgets/Navbar/Navbar";
import Footer from "../../../Widgets/Footer/Footer";
import styles from "./Interest.module.css";

const Interest = () => {
  return (
    <div className={styles.page}>
      <div style={{ height: "60px" }} />
      <Navbar />

      <div className={styles.main}>
        <div className={styles.hed1}>
          What is the Interest, Simple Interest and compound interest?
        </div>

        <div className={styles.body}>
          <p>
            <b> Interest - Finance</b> में इंटरेस्ट एक पेमेंट है जो कर्ज लेने
            वाले को देना पड़ता है और अगर किस ने बैंक में डिपॉज़िट किया है तो उसे
            डिपॉज़िट यानि मूलधन पर जो पैसा मिलता है उसे इंटरेस्ट या ब्याज कहते
            हैं ।
            <br />
            <br />
            दुसरे शब्दों में कहें तो इंटरेस्ट, पैसे को ब्याज पर लेने या बैंक में
            डिपॉज़िट करने की इनाम राशि है ।
          </p>
          <div className={styles.qes} />
          <p>
            <b>उदहारण</b> - मान लीजिये किसी बैंक में आपने 1 लाख रूपये का फिक्स
            डिपॉज़िट किया उस कुछ राशि आपको इनाम के तौर पर दी गयी जैसे 7000 रूपये
            तो यह 7000 की राशि आपका ब्याज होगा और उसकी दर 7% के हिसाब से होगी हो
            गई है।
          </p>
          <div className={styles.qes} />
          <b>इंटरेस्ट दो प्रकार का होता है</b>
          <br />
          <p>
            <b>(Simple Interest)</b> - इसे हिंदी में साधारण ब्याज कहा जाता है ,
            यह एक सरल गणना करने तरीका है मूलधन पर लगे हुए ब्याज का, इसका
            इस्तेमाल आज भी बैंकिंग, नॉन बैंकिंग सेक्टर में किया जाता है जैसे
            फिक्स डिपाजिट जिनकी अवधि १ वर्ष से कम, सर्टिफिकेट डिपाजिट ,इत्यादि
            में होता है |
          </p>
          <p>
            <b> (Compound Interest)</b> - आपने बहुत सी बार पुराने लोगों से सुना
            होगा ब्याज पर ब्याज इसे ही कंपाउंड इंटरेस्ट या हिंदी में चक्रवृद्धि
            ब्याज़ कहा जाता है, बहुत से विद्वानों का मानना है कि यह दुनिया का
            8वा अजूबा है |
          </p>
          <p>अल्बर्ट आइंस्टीन ने एक समय कहा था-</p>
          <p>
            <b>“ब्रह्मांड में सबसे शक्तिशाली बॉल चक्रवृद्धि ब्याज है”</b>
          </p>

          <img src="/blogs/alb.png" />

          <p>
            जहाँ आपको साधारण ब्याज में सिर्फ मूलधन पर ही ब्याज मिलता है वहीँ
            चक्रवृद्धि ब्याज़ में आपको न सिर्फ आपके मूलधन बल्कि आपके मूलधन पर
            लगे हुए ब्याज से संचित हुई राशि (मिश्रधन) पर भी ब्याज लगता है इसका
            उपयोग बैंकिंग, नॉन बैंकिंग सेक्टर में बहुत सी जगहों पर किया जाता है
            जैसे लम्बे समय के लिए होम लोन, पर्सनल लोन कार लोन इत्यादि में ।
          </p>

          <b>चक्रवृद्धि ब्याज का सूत्र </b>

          <img src="/blogs/for.png" />
          <p>
            जहाँ
            <ul>
              <li>A - राशि</li>
              <li>P - मूलधन</li>
              <li>n - समय</li>
              <li>r - ब्याज दर</li>
              <li>t- समय है</li>
            </ul>
          </p>
        </div>
        <div style={{ height: "120px" }} />
      </div>
      <Footer />
    </div>
  );
};

export default Interest;
