import React, { useState } from "react";
import Navbar from "../../Widgets/Navbar/Navbar";
import Footer from "../../Widgets/Footer/Footer";
import styles from "./Blogs.module.css";

const Blogs = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const blogs = [
    {
      id: 1,
      title: "जीरोधा का अलर्ट ट्रिगर्स ऑर्डर (ATO)",
      summary:
        "जीरोधा, भारत का अग्रणी डिस्काउंट ब्रोकर, जिसने हाल ही में एक नया और एडवांस फीचर पेश किया है जिसे अलर्ट ट्रिगर्स ऑर्डर (ATO) कहा जाता है। यह फीचर ट्रेडर्स को अधिक कुशलता से व्यापार (ट्रेड) करने में मदद करता है। इस गाइड में, हम ATO के बारे में विस्तार से जानेंगे और इसका उपयोग करने के तरीके समझेंगे।",
      link: "/ato",
    },
    {
      id: 2,
      title: "What is CFO to Net Profit",
      summary:
        "New Fundamental feature CFO to net profit unlocked what is this to know more",
      link: "https://youtu.be/XW2kUvGhw_Q?si=Z3VgB5wz1h_RWUyv",
    },
    {
      id: 3,
      title: "What is the Interest, Simple Interest and compound interest?",
      summary:
        "Finance में इंटरेस्ट एक पेमेंट है जो कर्ज लेने वाले को देना पड़ता है और अगर किस ने बैंक में डिपॉज़िट किया है तो उसे डिपॉज़िट यानि मूलधन पर जो पैसा मिलता है उसे इंटरेस्ट या ब्याज कहते हैं।",
      link: "/interest",
    },
    {
      id: 3,
      title: "SGX Nifty",
      summary:
        "SGX Nifty is a derivative/futures contract of the Nifty 50 that is traded on the Singapore Exchange (SGX).",
      link: "/sgx_nifty",
    },
  ];

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredBlogs = blogs.filter(
    (blog) =>
      blog.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      blog.summary.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className={styles.page}>
      <Navbar />
      <div style={{ height: "60px" }} />

      <div className={styles.main}>
        <div className={styles.sec1}>
          <h1 className={styles.hed1}>Find Educational Blogs</h1>
          <div className={styles.searchBox}>
            <input
              className={styles.searchInput}
              placeholder="Enter Keyword"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <button>Search</button>
          </div>
          <p>
            Search about the latest, trending & Educational topics related to
            Stock Market
          </p>
        </div>

        <div className={styles.body}>
          <ul className={styles.blogs}>
            {filteredBlogs.length > 0 ? (
              filteredBlogs.map((blog) => (
                <li key={blog.id} onClick={() => window.open(blog.link)}>
                  <h4>{blog.title}</h4>
                  {blog.summary}
                  <br />
                  <p className={styles.rdM}>Read More...</p>
                </li>
              ))
            ) : (
              <li>No blogs found.</li>
            )}
          </ul>
        </div>
        <div style={{ height: "120px" }} />
      </div>
      <Footer />
    </div>
  );
};

export default Blogs;
