// src/SignupForm.js
import React, { useState } from "react";
import styles from "./Getin.module.css";
import Navbar from "../../Widgets/Navbar/Navbar";
import { Link } from "react-router-dom";

const Getin = () => {
  return (
    <div
      style={{
        paddingTop: "60px",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Navbar />

      <div className={styles.main_Con}>
        <div className={styles.cardContainer}>
          <div className={styles.card}>
            <h2>Welcome!</h2>
            <p style={{ textAlign: "left" }}>
              If you are already registered with us, Please chooose Login <br />
              <br />
              If you are viaiting for the first time, Please choose Signup
            </p>
            <div className={styles.buttonContainer}>
              <button className={`${styles.btn} ${styles.loginBtn}`}>
                <Link to="/login">Log in</Link>
              </button>
              <button className={`${styles.btn} ${styles.signupBtn}`}>
                <Link to="/signup">Sign up</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Getin;
