import React, { useState } from "react";
import Navbar from "../../Widgets/Navbar/Navbar";
import styles from "./ContactUs.module.css";
import Footer from "../../Widgets/Footer/Footer";
import { sendContactUsEnquiry } from "../../api/apis";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  const [firstName, FirstName] = useState("");
  const [middleName, MiddleName] = useState("");
  const [lastName, LastName] = useState("");
  const [email, Email] = useState("");
  const [mob, Mob] = useState("");
  const [sub, Sub] = useState("");
  const [message, Message] = useState("");

  const [isSent, IsSent] = useState(false);

  const handleSubmit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      alert("Please Enter a valid Email Address");
      return;
    }
    IsSent(true);
    sendContactUsEnquiry({
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      email: email,
      phoneNumber: mob,
      subject: sub,
      message: message,
    })
      .then((res) => alert(`Your Ticket has been generated.\n ${res.ticket}`))
      .finally(() => IsSent(false));
    FirstName("");
    LastName("");
    MiddleName("");
    Email("");
    Mob("");
    Sub("");
    Message("");
    setTimeout(() => IsSent(false), 2000);
  };

  return (
    <div className={styles.page}>
      <Navbar />
      <div style={{ height: "60px" }} />

      <Helmet>
        <title>Lucknow Lions - Contact US</title>
        <meta
          name="keywords"
          content="stock broker in lucknow, best stock broker in lucknow, 0 brokerage on delivery, free demat account"
        />
        <meta name="robots" content="index" />
      </Helmet>

      <div className={styles.bannerCointainer}>
        <img
          src={process.env.PUBLIC_URL + "/contactus_banner.svg"}
          alt="Lucknow Lions"
          className={styles.bannerImg}
        />
        <div className={styles.overText}>
          <span className={styles.textStyle}>C</span>
          ontact Us
        </div>
      </div>

      <div className={styles.main}>
        <div className={styles.container}>
          <div className={styles.child_left}>
            <div className={styles.main_hed}>Contact Information</div>
            <div className={styles.para}>
              Say something to Start a live chat
            </div>
            <div style={{ height: "50px" }} />
            <div className={styles.flx}>
              <div>
                <div className={styles.box_hed}>Support related</div>
                contact@lucknowlions.com
              </div>
              {/* <div>
                <div className={styles.box_hed}>Contact Timing</div>
               24X7
              </div> */}
            </div>
            <div style={{ marginTop: "20px" }}>
              <div className={styles.box_hed}>Contact Timing</div>
              24X7
            </div>
            <img
              src="/contactus_img.png"
              alt="Contact Us"
              className={styles.bottom_image}
            />
          </div>

          {/* <div className={styles.divider} /> */}

          <div className={styles.child_right}>
            <div>
              <div className={styles.flx_btm}>
                <div className={styles.flx_hlf}>
                  First Name
                  <input
                    type="text"
                    value={firstName}
                    onChange={(v) => {
                      const value = v.target.value;
                      if (value.length > 15) return;
                      FirstName(value);
                    }}
                    class={styles.bottom_line_input}
                    placeholder="Enter your First name"
                  />
                  <div className={styles.err}></div>
                </div>

                <div style={{ marginRight: "40px" }} />

                <div className={styles.flx_hlf}>
                  Middle Name
                  <input
                    type="text"
                    value={middleName}
                    onChange={(v) => {
                      const value = v.target.value;
                      if (value.length > 15) return;
                      MiddleName(value);
                    }}
                    class={styles.bottom_line_input}
                    placeholder="Enter your Middle name"
                  />
                </div>

                <div style={{ marginRight: "40px" }} />

                <div className={styles.flx_hlf}>
                  Last Name
                  <input
                    type="text"
                    value={lastName}
                    onChange={(v) => {
                      const value = v.target.value;
                      if (value.length > 15) return;
                      LastName(value);
                    }}
                    class={styles.bottom_line_input}
                    placeholder="Enter your Last name"
                  />
                </div>
              </div>

              <div className={styles.flx_btm}>
                <div className={styles.flx_hlf}>
                  Email
                  <input
                    type="text"
                    value={email}
                    onChange={(v) => {
                      const value = v.target.value;
                      if (value.length > 30) return;
                      Email(value);
                    }}
                    class={styles.bottom_line_input}
                    placeholder="Enter your Email address"
                  />
                </div>

                <div style={{ marginRight: "80px" }} />

                <div className={styles.flx_hlf}>
                  Phone Number
                  <input
                    type="text"
                    value={mob}
                    onChange={(v) => {
                      const value = v.target.value;
                      const numericValue = value.replace(/[^0-9]/g, "");
                      if (numericValue.length > 10) return;
                      Mob(numericValue);
                    }}
                    class={styles.bottom_line_input}
                    placeholder="Enter your Phone number"
                  />
                </div>
              </div>

              <div className={styles.field}>
                Subject
                <input
                  type="text"
                  value={sub}
                  onChange={(v) => {
                    const value = v.target.value;
                    if (value.length > 50) return;
                    Sub(value);
                  }}
                  class={styles.bottom_line_input}
                  placeholder="Enter your Subject"
                />
              </div>

              <div className={styles.field}>
                Message
                <input
                  type="text"
                  value={message}
                  onChange={(v) => {
                    const value = v.target.value;
                    if (value.length > 300) return;
                    Message(value);
                  }}
                  class={styles.bottom_line_input}
                  placeholder="Enter your Message"
                />
              </div>

              {isSent ? (
                <img src="/aero.gif" />
              ) : (
                <React.Fragment>
                  <button onClick={handleSubmit} className={styles.btn}>
                    Send Message
                  </button>
                  <img style={{ height: "302px" }} src="/contactus_4.gif" />
                </React.Fragment>
              )}

              {/* <div className={styles.add}>
                <div className={styles.box_hed}>We love to hear from you</div>
                Waiting for you Message
              </div> */}
            </div>
          </div>
        </div>
        <div className={styles.more_info}>
          <div style={{ fontWeight: "700", marginBottom: "30px" }}>
            More Information
          </div>
          <div className={styles.flx}>
            <div className={styles.card}>
              <img className={styles.card_img} src="./contactus_1.svg" />
              <div className={styles.card_hed}>General Information</div>
              <div className={styles.card_bd}>contact@lucknowlions.com</div>
            </div>
            <div className={styles.card}>
              <img className={styles.card_img} src="./contactus_2.svg" />
              <div className={styles.card_hed}>CEO</div>
              <div className={styles.card_bd}>help.ceo@lucknowlions.com</div>
            </div>
            <div className={styles.card}>
              <img className={styles.card_img} src="./contactus_3.svg" />
              <div className={styles.card_hed}>Write Us</div>
              <div className={styles.card_bd}>
                <b style={{ fontSize: "17px" }}>Lucknow Lions</b>
                8A, Vrindavan Vihar, Gaushala Road, Balaganj, Lucknow : 226003
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
